//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'managerIndex',
  data() {
    return {
      tabtype: 'curr',
      list: [],
      loading: false,
      finished: false,
      pager: {
        pageIndex: 1,
        pageSize: 10,
        rowsCount: 99,
        start: 0
      },
      storeId:''
    };
  },
  created() {
    if (this.$route.query.storeId) {
      this.storeId = this.$route.query.storeId
    }
  },
  methods: {

    getCateData() {
      this.list = []
      this.pager.pageIndex = 1
      this.loading = false
      this.finished = false
      // this.getData()
    },
    getData() {
      let _this = this;
      this.loading = true;
      this.axios
        .post("/worktask/wxcp/task/getManageList?storeId=" + this.storeId + "&taskRecordStatus=" + this.tabtype, {
        })
        .then((res) => {
          console.log(res);
          if(res.data.data && res.data.data.length > 0){
            res.data.data.map(v=>{
              v.completeNum = 0;
              v.list.map(item=>{
                if(item.status == 0){
                  v.completeNum++
                }
              })
              v.completeRate = v.list.length == 0 ? '100%' : ((v.completeNum * 100 / v.list.length).toFixed(0) + '%')
            })
          }
          _this.list = res.data.data || []
          _this.loading = false;
          _this.finished = true;
        });
        return
      let postData = {
        pager: {
          ...this.pager,

        },
        queryMap: {
          taskType: this.tabcate,
          taskRecordStatus: this.tabtype,
          status: 1
        },
        queryMapObject: {}
      }
      this.loading = true;
      this.axios
        .post(
          "/worktask/wxcp/task/recordList", postData
        )
        .then((res) => {
          // this.$toast.clear();
          console.log(res)
          if (res.data.code == 0 && res.data.data && res.data.data.list.length > 0) {
            res.data.data.list.map(v=>{
              v.isOpen = false
            })
            this.list = this.list.concat(res.data.data.list)
            this.pager.pageIndex++
            // 加载状态结束
            this.loading = false;
            this.finished = false;
          } else {
            this.loading = false;
            this.finished = true;
          }

        });
    }, 
    toggleItem(item) {
      if(item.isOpen){
        item.isOpen = false
      }else{
        item.isOpen = true
      }
      this.$forceUpdate()
      return
      let _this = this
      if (item.taskInfo.taskType == 'birthday') {
        this.$router.push('/taskManage/birthDetail?id=' + item.id)
      }else if (item.taskInfo.taskType == 'assess') {
        this.axios
        .get(
          "/worktask/wxcp/task/getRecord?id=" + item.id
        )
        .then((res) => {
          // this.$toast.clear();
          console.log(res)
          if(res.data.data && res.data.data.inputMap && res.data.data.inputMap.modelId){
            _this.$router.push('/storeManagement/placesadjustment?assessId=' + res.data.data.inputMap.modelId)
          }
        });
      } else {
        this.$router.push('/taskManage/detail?id=' + item.id)
      }
    }
  },
  activated() {
    window.document.title = '任务列表';
    this.list = []
    this.pager.pageIndex = 1
    this.loading = false
    this.finished = false
    // this.getData()
  },
  mounted() {
    document.title = "任务列表";
  },
};
